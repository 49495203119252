$header-height: 5vh;
$footer-height: 5vh;
$content-height: 100vh - $header-height - $footer-height;

$map-width: 70vw;
$side-bar-width: 100vw - $map-width;

$padding: 10px;
$margin: 15px;

$devices: (
        "tiny": ("from": 0px, "to": 320px),
        "mobile": ("from": 320px, "to": 480px),
        "tablet": ("from": 481px, "to": 768px),
        "laptop": ("from": 769px, "to": 1024px),
        "desktop": ("from": 1025px, "to": 1200px),
        "smarttv": ("from": 1201px, "to": 2000px),
)