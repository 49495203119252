@import 'consts';
@import 'mixins';

html {
  background-color: rgb(33, 37, 46);
  // overflow-y: hidden;
}

.App {
  text-align: center;

  input {
    margin: $margin/2;
  }

  .close {
    @include flex-center(center, center);
    @include border(black);
    @include shadow(black);
    color: black;
    cursor: pointer;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: sandybrown;
    margin-left: auto;
    font-size: 11pt;
    margin-right: -2px;
    margin-top: -3px;
  }

  .close:hover {
    background-color: darkslategrey;
    color: snow;
    @include shadow(crimson);
  }
}
