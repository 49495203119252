@use "sass:map";
@import "src/consts";

$tiny: map-get($devices, "tiny");
$tiny-from: map-get($tiny, "from");
$tiny-to: map-get($tiny, "to");
$mobile: map-get($devices, "mobile");
$mobile-from: map-get($mobile, "from");
$mobile-to: map-get($mobile, "to");
$tablet: map-get($devices, "tablet");
$tablet-from: map-get($tablet, "from");
$tablet-to: map-get($tablet, "to");
$laptop: map-get($devices, "laptop");
$laptop-from: map-get($laptop, "from");
$laptop-to: map-get($laptop, "to");
$desktop: map-get($devices, "desktop");
$desktop-from: map-get($desktop, "from");
$desktop-to: map-get($desktop, "to");
$smart-tv: map-get($devices, "smarttv");
$smart-tv-from: map-get($smart-tv, "from");
$smart-tv-to: map-get($smart-tv, "to");

@mixin device($styles, $class, $from, $to) {
  @media(min-width: $from) and (max-width: $to) {
    .#{$class} {
      @each $style, $value in $styles {
        #{$style}: #{$value};
      }
    }
  }
}
@mixin tiny-devices-only($styles, $class) {
  @media(min-width: $tiny-from) and (max-width: $tiny-to) {
    .#{$class} {
      @each $style, $value in $styles {
        #{$style}: #{$value};
      }
    }
  }
}
@mixin mobile-devices-only($styles, $class) {
  @media(min-width: $mobile-from) and (max-width: $mobile-to) {
    .#{$class} {
      @each $style, $value in $styles {
        #{$style}: #{$value};
      }
    }
  }
}

@mixin tablets-devices-only($styles, $class) {
  @media(min-width: $tablet-from) and (max-width: $tablet-to) {
    .#{$class} {
      @each $style, $value in $styles {
        #{$style}: #{$value};
      }
    }
  }
}

@mixin other-devices-only($styles, $class) {
  @media(min-width: $tablet-to) and (max-width: $smart-tv-to) {
    .#{$class} {
      @each $style, $value in $styles {
        #{$style}: #{$value};
      }
    }
  }
}

@mixin flex-center($vertical, $horizontal) {
  display: flex;
  text-align: center;
  justify-content: $horizontal;
  align-items: $vertical;
}

@mixin border($color) {
  padding: $padding;
  margin: $margin;
  border: 1px solid $color;
  border-radius: 3px;
}

@mixin shadow($color) {
  -webkit-box-shadow: 0 1px 1px 1px $color;
  -moz-box-shadow: 0 1px 1px 1px $color;
  box-shadow: 0 1px 1px 1px $color;
}

@mixin support-font($font-size, $color) {
  font-family: "Droid Sans Mono", serif;
  font-size: $font-size;
  color: $color;
}