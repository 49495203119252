@import "src/consts";
@import "src/mixins";
$color: silver;

.SideBar {
  background: rgb(33, 37, 46);
  color:whitesmoke;

  @include flex-center(center, center);
  flex-direction: column;
  width: $side-bar-width;
  
  .coordinates {
    @include flex-center(center, center);
    flex-direction: column;
    width: 170;
    height: 200; 
    padding: 10; 
    margin: 10px;
    div {
      margin: 10px;
    }
  }

  input {
    color: black !important;
    width: 100px;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    height: 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }


  label {
    color: $color;
    margin-bottom: 4px;
    top: 1px;
    left: 10px;
  }

  .control {
    @include flex-center(center, center);
    flex-direction: column;
    width: 100%;
    // overflow-y: visible;
  }

  .MuiOutlinedInput-root {
    margin-bottom: 10px;
  }

  .MuiRadio-root {
    padding: 3px;
  }
}

@include device(
  (
    "flex-direction": "row !important;",
  ),
  control,
  $mobile-from,
  $smart-tv-to,
);

@include device(
  (
    "width": "100%",
  ),
  SideBar,
  $tiny-from,
  $desktop-from
);
