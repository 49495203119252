@import 'src/mixins.scss';
@import 'src/consts.scss';

.Header {
  @include flex-center(center, flex-start);
  display: flex;
  background: rgb(33, 37, 46);
  color: sandybrown;
  height: $header-height;
  padding-left: 5px;

  span {
    font-family: 'Oswald', sans-serif;
    text-shadow: 0 0 3px #080101, 0 0 5px #88735b;
    margin-left: 10px;
    font-size: 21pt;
  }
  .logout {
    margin-left: auto;
    margin-right: $margin;
  }
}
.rotate {
  display: inline-block;
  cursor: pointer;
  animation: blinker 10s linear infinite;
  @keyframes blinker {
    0%,
    100% {
    }
    50% {
      color: red;
    }
  }
}
