@import 'src/consts';
@import 'src/mixins';

.Map {
  @include flex-center(center, center);
  background-color: aqua;
  width: $map-width;
}

svg {
  width: 30px;
  height: 30px;
}

textarea {
  margin-top: $margin;
  padding: $padding/3;
  height: 100px;
  min-width: 200px;
  width: 98%;
  resize: none;
}

button {
  margin-top: $margin;
}

.leaflet-popup-content-wrapper {
  text-align: right !important;
}

.marker-info {
  display: flex;
  flex-direction: column;

  .description {
    color: green;
  }

  .blog-post {
    background-color: snow;
  }

  div {
    text-align: left;
    margin-bottom: 10px;
    color: dodgerblue;
    cursor: pointer;

    &.images {
      background-color: silver;
      max-height: 200px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img {
        width: 25%;
        max-height: 30%;
        margin: 5px;
      }
    }
  }
}

section {
  &.dropzone {
    color: dodgerblue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: 1px solid silver;
    border-radius: 3px;
    margin-top: 10px;
    @include shadow(silver);

    &:hover {
      background-color: darken(whitesmoke, 5);
      @include shadow(grey);
    }
  }
}

.preview {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  height: 75px;
  overflow: hidden;

  .delete-image {
    max-width: 70px;

    img {
      width: 100%;
      height: 100%;

      &:hover {
        @include shadow(red);
      }
    }
  }
}

.leaflet-popup-content-wrapper {
  border: 1px solid silver;
}

.leaflet-popup-content {
  width: 210px !important;
  max-height: 350px !important;
  textarea {
    max-width: 200px;
  }
}

@include other-devices-only(
  (
    'width': '99% !important',
  ),
  leaflet-container
);

.leaflet-container a.leaflet-popup-close-button {
  color: indigo;
}

@include device(
  (
    'width': '100vw',
    'height': '50vh',
  ),
  Map,
  $tiny-from,
  $desktop-from
);
