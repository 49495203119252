@import "src/consts";
@import "src/mixins";

.Auth {
  min-width: 250px;
  max-width: 300px;
  display: flex;
  text-align: center;
  @include flex-center(center, center);
  padding: 20px;
  margin-top: -10px;

  .form {
    // margin-top: 30px;
    @include flex-center(center, center);
  }

  .header {
    background-color: whitesmoke;
    @include border(snow);
    @include shadow(silver);
    color: black;
    margin-bottom: $margin;
  }

}
