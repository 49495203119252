@import "src/mixins.scss";
@import "src/consts.scss";

.Content {
  display: flex;
  height: $content-height;
  // overflow-y: visible;
}

@include device(
  (
    "flex-direction": "column",
  ),
  Content,
  $tiny-from,
  $desktop-from
);
