.type-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-top: 7px;
  height: 80px;

  .marker-type {
    width: 100%;
  }

  #marker-types{
    display: flex;
  }

  select {
    margin-left: 10px;
  }

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    width: 30px !important;
    height: 30px !important;
    margin-top: -3px;
    cursor: pointer;    
  }

  div {
    svg {
      margin-right: 5px;
      width: 35px;
    }
  }
}

.MuiInputBase-root {
  background-color: snow;
  input {
    height: 30px;
    margin: 2px !important;
    padding: 10px;
  }
}